var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
var validate = ref.validate;
return [_c('SfHeading',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-heading'),expression:"'user-account-heading'"}],staticClass:"sf-heading--left sf-heading--no-underline title",attrs:{"level":3,"title":_vm.$t('User Account')}}),_vm._v(" "),_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();validate().then(handleSubmit(_vm.handleFormSubmit(reset, _vm.nextStep)))}}},[_c('div',{staticClass:"form"},[_c('ValidationProvider',{attrs:{"name":"firstname","rules":_vm.loginUserAccount ? '' : 'required|min:2',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-firstName'),expression:"'user-account-firstName'"}],staticClass:"form__element form__element--half",attrs:{"label":_vm.$t('First Name'),"name":"firstName","required":!_vm.loginUserAccount,"disabled":_vm.loginUserAccount,"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"lastname","rules":_vm.loginUserAccount ? '' : 'required|min:2',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-lastName'),expression:"'user-account-lastName'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"label":_vm.$t('Last Name'),"name":"lastName","required":!_vm.loginUserAccount,"disabled":_vm.loginUserAccount,"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","rules":"email|required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-email'),expression:"'user-account-email'"}],staticClass:"form__element form__element--half",attrs:{"label":_vm.$t('E-mail'),"name":"email","required":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),(_vm.createUserAccount || _vm.loginUserAccount)?_c('ValidationProvider',{attrs:{"rules":"required|min:8|password","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('user-account-password'),expression:"'user-account-password'"}],staticClass:"form__element form__element--half form__element--half-even",attrs:{"name":"password","label":"Password","type":"password","required":"","has-show-password":"","valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}):_vm._e()],1),_vm._v(" "),(!_vm.isAuthenticated)?_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('create-account'),expression:"'create-account'"}],staticClass:"form__element",attrs:{"label":"Create an account","name":"createUserAccount","disabled":_vm.loginUserAccount},model:{value:(_vm.createUserAccount),callback:function ($$v) {_vm.createUserAccount=$$v},expression:"createUserAccount"}}):_vm._e(),_vm._v(" "),(!_vm.isAuthenticated)?_c('SfCheckbox',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-account'),expression:"'login-account'"}],staticClass:"form__element",attrs:{"label":"Login","name":"loginUserAccount","disabled":_vm.createUserAccount},model:{value:(_vm.loginUserAccount),callback:function ($$v) {_vm.loginUserAccount=$$v},expression:"loginUserAccount"}}):_vm._e(),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form__action"},[_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('continue-to-shipping'),expression:"'continue-to-shipping'"}],staticClass:"form__action-button",attrs:{"type":"submit","disabled":!_vm.canMoveForward}},[_vm._v("\n          "+_vm._s(_vm.$t('Continue to ' + (_vm.nextStep.title || 'next step')))+"\n        ")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }